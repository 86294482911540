/* eslint-disable no-new-func */
import { rendererActions } from "./slice";
import { getValueByPath } from "../mods";
import { getBindParams } from "../builder/modules";
import { ft } from "@bsgp/form-and-table";
import {
  isString,
  isTruthy,
  tryit,
  isFunction,
  clone,
  isArray
} from "@bsgp/lib-core";
import { apiHub, s3, file } from "@bsgp/lib-api";

const lib = {
  "@bsgp/lib-api": require("@bsgp/lib-api"),
  "@bsgp/lib-core": require("@bsgp/lib-core"),
  "@bsgp/lib-date": require("@bsgp/lib-date"),
  "@bsgp/lib-export": require("@bsgp/lib-export"),
  "@bsgp/lib-quantity": require("@bsgp/lib-quantity"),
  jsbarcode: require("jsbarcode")
};

const reducedApiHub = {
  get: apiHub.get,
  post: apiHub.post,
  patch: apiHub.patch,
  put: apiHub.put
};

const getValueFromBind = (data, prop) => {
  if (!data) {
    return prop;
  }
  const params = getBindParams(prop);
  return params.reduce((acc, each) => {
    const newValue =
      data[
        each
          .replace("{{", "")
          .replace("}}", "")
          .trim()
      ];
    return acc.replace(each, newValue === undefined ? "" : newValue);
  }, prop);
};

export const getProperties = (
  comp,
  { formKey, fieldKey, tableKey, props, functions, entryPath, builder }
) => {
  const { _state } = props;
  const { data } = _state;

  const propsKeys = Object.keys(comp).filter(
    key => !["bindData", "key"].includes(key)
  );
  const conv = comp.conv ? comp.conv.value : undefined;

  const newProps = propsKeys.reduce((acc, propKey) => {
    const prop = clone(comp[propKey]);
    if (prop.ignore === true) {
      return acc;
    }

    let propValue;
    if (Object.hasOwn(prop, "value")) {
      propValue = getValueFromBind(comp.bindData, prop.value);
    } else {
      propValue = getValueFromBind(comp.bindData, prop);
    }

    switch (propKey) {
      case "state": {
        if (
          //* template literal일 때
          propValue.startsWith("{") &&
          propValue.endsWith("}")
        ) {
          const literalValue = `{=$${propValue}}`;

          acc[propKey] = literalValue;
        } else if (propValue) {
          acc[propKey] = convertByType(propValue, prop.type, {
            props,
            key: propKey,
            context: {},
            functions
          });
        } else {
          acc[propKey] = window.sap.ui.core.ValueState.None;
        }

        break;
      }
      case "onConfirm":
      case "onSort":
      case "onSubmit":
      case "onChange":
      case "onPress":
      case "onNodeDoubleClick":
      case "onError":
      case "onScan": {
        acc[propKey] = fn =>
          fn.getMetaFuncContent({
            tableKey,
            formKey,
            fieldKey,
            functions,
            componentKey: comp.key || fieldKey,
            type: propKey,
            functionUid: propValue,
            conv
          });
        break;
      }
      case "onSelect": {
        const options = Object.entries(comp);
        const checkList = options.reduce(
          (acc2, [optionKey, optionValue]) => {
            if (
              optionKey === "closeDialogOnSelect" &&
              optionValue.value === "true"
              // optionValue.value === true
            ) {
              acc2.closeDialogOnSelect = true;
            } else if (
              optionKey === "mode" &&
              ["s", "single"].includes(optionValue.value)
            ) {
              acc2.selectMode = true;
            }

            return acc2;
          },
          {
            closeDialogOnSelect: false,
            selectMode: false
          }
        );

        acc[propKey] = fn =>
          fn.getMetaFuncContent({
            // dialogKey,
            // mainKey,
            // componentKey,
            tableKey,
            type: propKey,
            functionUid: propValue,
            functions,
            conv: conv,
            autoClose: !!(checkList.closeDialogOnSelect && checkList.selectMode)
          });
        break;
      }

      case "properties": {
        acc.properties = {};
        const entries = Object.entries(prop);

        entries.forEach(each => {
          const propsKey = each[0];
          if (each[1].ignore === true) {
            return;
          }

          const propsValue = getValue(
            [
              { key: propsKey, value: getValueFromBind(comp.bindData, each[1]) }
            ],
            {
              props,
              entryPath,
              fieldKey,
              functions
            }
          );

          acc.properties[propsKey] = propsValue;
        });

        break;
      }
      case "valueHelpV2": {
        const { dialogId, onRequest, onConfirm } = prop;

        if (dialogId === undefined) {
          break;
        }

        acc[propKey] = {
          dialogId: getValueFromBind(comp.bindData, dialogId.value)
        };

        if (onRequest) {
          acc[propKey].onRequest = fn =>
            fn.getMetaFuncContent({
              // dialogKey,
              // mainKey,
              // componentKey,
              tableKey,
              type: "onRequest",
              functionUid: onRequest.value,
              functions,
              conv: conv
            });
        }
        if (onConfirm) {
          acc[propKey].onConfirm = fn =>
            fn.getMetaFuncContent({
              // dialogKey,
              // mainKey,
              // componentKey,
              tableKey,
              type: "onConfirm",
              functionUid: onConfirm.value,
              functions,
              conv: conv
            });
        }

        break;
      }
      case "unit": {
        const { unit } = comp;
        if (unit.type === "Path") {
          acc[propKey] = getValueByPath(data, unit.value);
        } else {
          acc[propKey] = unit.value;
        }

        break;
      }
      case "list": {
        const { key, list } = comp;

        acc.list = getValue([{ key, value: list }], {
          props,
          entryPath,
          fieldKey,
          functions
        });

        break;
      }
      default: {
        if (isArray(propValue)) {
          /*
          case "columns":
          case "containers":
          case "components":
          case "fields":
          */
          return acc;
        }
        acc[propKey] = convertByType(propValue, prop.type, {
          props,
          key: propKey,
          context: {},
          functions
        });
        break;
      }
    }

    return acc;
  }, {});

  // if (["Input", "Select"].includes(comp.type)) {
  if (newProps.onChange === undefined) {
    newProps.onChange = fn =>
      fn.getMetaFuncContent({
        // dialogKey,
        formKey,
        tableKey,
        fieldKey,
        componentKey: comp.key || fieldKey,
        functions,
        type: "onChange",
        functionUid: "",
        conv
      });
  }
  // }

  return newProps;
};

export const addObjects = (
  builder,
  { forms, tables, codeeditors, nodeeditors, functions, props, entryPath }
) => {
  const { _state } = props;
  const { data } = _state;

  if (isTruthy(forms)) {
    forms
      .filter(each => each.ignore !== true)
      .forEach((form, fIdx) => {
        const formData = {
          title: form.title,
          onSelect: fn => fn.selectFormId(),
          ...getProperties(form, {
            props,
            functions,
            builder,
            entryPath
          })
        };
        // console.log("formData", form.key, clone(formData));
        builder.addForm(form.key, formData);

        form.containers
          .filter(each => each.ignore !== true)
          .forEach((container, cIdx) => {
            const contData = {
              title: container.title,
              ...getProperties(container, {
                props,
                functions,
                builder,
                entryPath
              })
            };
            // console.log("contData", container.key, clone(contData));
            builder.addContainer(container.key, contData);

            container.fields
              .filter(each => each.ignore !== true)
              .reduce((acc, field) => {
                if (field.binding) {
                  const dataList = getValueByPath(data, field.binding);
                  if (dataList) {
                    dataList.forEach(each => {
                      const newField = clone(field);
                      newField.bindData = each;
                      newField.key = getValueFromBind(
                        newField.bindData,
                        newField.key
                      );
                      acc.push(newField);
                    });
                  }
                } else {
                  acc.push(clone(field));
                }
                return acc;
              }, [])
              .forEach(field => {
                // let dialogKey = dialog.key;

                const { components } = field;

                const convValues = components
                  .filter(each => each.ignore !== true)
                  .reduce((acc, each) => {
                    if (each.conv) {
                      acc = acc.concat(each.conv.value);
                    } else {
                      acc = acc.concat("");
                    }
                    return acc;
                  }, []);

                builder.addField(field.key, {
                  ...getProperties(field, {
                    props,
                    functions,
                    builder,
                    entryPath
                  }),
                  value: getValue(
                    components
                      .filter(each => each.ignore !== true)
                      .map(each => ({ ...each, bindData: field.bindData })),
                    {
                      formKey: form.key,
                      fieldKey: field.key,
                      props,
                      entryPath,
                      functions
                    }
                  ),
                  conv: convValues.length === 1 ? convValues[0] : convValues,
                  component: getSingleOrMultiComp(
                    components
                      .filter(each => each.ignore !== true)
                      .map(each => ({ ...each, bindData: field.bindData })),
                    {
                      // dialogKey,
                      formKey: form.key,
                      fieldKey: field.key,
                      props,
                      functions,
                      builder,
                      entryPath
                    }
                  )
                });
              });
          });
      });
  }

  if (isTruthy(tables)) {
    const selectedTableList = [];
    const hasSpecificTablesForForm =
      forms.findIndex(form => tryit(() => isTruthy(form.table.value), false)) >=
      0;
    if (hasSpecificTablesForForm === true) {
      forms
        .filter(form => form.key && form.key === data.selectedFormId)
        .forEach(form => {
          const tableValue = tryit(() => form.table.value) || [];

          if (tableValue) {
            selectedTableList.push(...tableValue);
          }
        });
    }

    renderTables(builder, {
      tables: tables
        .filter(each => each.ignore !== true)
        .filter(table => {
          const properties =
            getProperties(table, { props, builder, entryPath, functions })
              .properties || {};
          return properties.visible !== false;
        }),
      data,
      entryPath,
      selectedTableList,
      props,
      hasSpecificTablesForForm,
      functions
    });
  }

  if (isTruthy(codeeditors)) {
    codeeditors
      .filter(each => each.ignore !== true)
      .forEach(codeeditor => {
        builder.addCode(codeeditor.key, {
          title: codeeditor.title,
          ...getProperties(codeeditor, {
            props,
            functions,
            builder,
            entryPath
          }),
          value: getValue([codeeditor], { props, entryPath, functions }),
          buttons: codeeditor.components
            .filter(each => each.ignore !== true)
            .map(component => {
              return {
                name: component.key,
                value: component.text
                  ? component.text
                  : getValue([component], {
                      props,
                      entryPath,
                      functions
                    }),
                component: ft[component.type || "Button"]({
                  ...getProperties(component, {
                    props,
                    functions,
                    builder,
                    entryPath
                  })
                })
              };
            })
        });
      });
  }

  if (isTruthy(nodeeditors)) {
    nodeeditors
      .filter(each => each.ignore !== true)
      .forEach(nodeeditor => {
        builder.addNode(nodeeditor.key, {
          title: nodeeditor.title,
          ...getProperties(nodeeditor, {
            props,
            functions,
            builder,
            entryPath
          }),
          value: getValue([nodeeditor], { props, entryPath, functions }),
          toolbar: {
            properties: {},
            content: nodeeditor.components
              .filter(each => each.ignore !== true)
              .map(component => {
                return {
                  name: component.key,
                  value: component.text
                    ? component.text
                    : getValue([component], {
                        props,
                        entryPath,
                        functions
                      }),
                  component: ft[component.type || "Button"]({
                    ...getProperties(component, {
                      props,
                      functions,
                      builder,
                      entryPath
                    })
                  })
                };
              })
          }
        });
      });
  }
};

function convertByType(value, type, { props, key, context, functions }) {
  const { _state } = props;
  const { data, meta } = _state;

  switch (type) {
    case "Function": {
      context.componentKey = key;

      const returnedValue = executeFunction(props, {
        functionIdentifier: [`component(${key})`, `property(value)`].join(":"),
        functions: functions || meta.functions,
        uid: value,
        moreContext: context,
        isAsync: false
      });

      return returnedValue;
    }
    case "Path":
      return getValueByPath(data, value);
    case "Number":
      return tryit(() => Number(value), value);
    case "Array":
      return tryit(() => JSON.parse(value), value);
    case "Boolean": {
      return tryit(() => value === "true", false);
    }
    case "String":
    default:
      return value;
  }
}

export const getValue = (
  components,
  { props, entryPath, formKey, fieldKey, returnContextKeys, functions }
) => {
  const { _state } = props;
  const { data } = _state;

  const context = {
    formKey: formKey,
    componentKey: undefined
  };

  if (returnContextKeys === true) {
    return Object.keys(context);
  }

  const results = components.map(comp => {
    const { key, value: valueFromMeta, bindData } = comp;

    if (valueFromMeta === undefined) {
      const compKey = key || fieldKey;
      return tryit(() => data.forms[formKey][compKey]);
    }

    const newValue = getValueFromBind(bindData, valueFromMeta.value);

    return convertByType(newValue, valueFromMeta.type, {
      props,
      key,
      context,
      functions
    });
  });

  if (results.length === 0) {
    return "";
  } else if (results.length === 1) {
    return results[0];
  } else {
    return results;
  }
};

export const getItems = (
  table,
  { props, entryPath, returnContextKeys, functions }
) => {
  const { _state } = props;

  const context = {
    tableKey: table.key
  };

  if (returnContextKeys === true) {
    return Object.keys(context);
  }

  const { items } = table;

  if (isTruthy(items) && items.ignore !== true) {
    const uid = items.value;
    return executeFunction(props, {
      functions: functions || _state.meta.functions,
      functionIdentifier: `table(${table.key}):property(items)`,
      uid,
      moreContext: context,
      isAsync: false
    });
    // const { content } = functions[uid];

    // const textFunc = `return ${content}`;
    // const customFunc = new Function(textFunc)();

    // return customFunc(state, context);
  } else if (isTruthy(_state.data.tables[table.key])) {
    return _state.data.tables[table.key];
  }
};

const renderTables = (
  builder,
  {
    tables,
    data,
    entryPath,
    selectedTableList,
    props,
    hasSpecificTablesForForm,
    functions
  }
) => {
  // meta, data, location, history,
  tables.forEach((table, tIdx) => {
    if (
      selectedTableList.length > 0 &&
      !selectedTableList.includes(table.key)
    ) {
      return;
    } else if (
      selectedTableList.length === 0 &&
      hasSpecificTablesForForm === true
    ) {
      return;
    }

    builder.addTable(`${table.key}_${tIdx}`, {
      title: table.title,
      ...getProperties(table, {
        props,
        builder,
        entryPath,
        functions
      })
    });

    if (table.isSearch) {
      builder.addToolbarSearch();
    }

    table.columns
      .filter(each => each.ignore !== true)
      .reduce((acc, col) => {
        if (col.binding) {
          const dataList = getValueByPath(data, col.binding);
          if (dataList) {
            dataList.forEach(each => {
              const newCol = clone(col);
              newCol.bindData = each;
              newCol.key = getValueFromBind(newCol.bindData, newCol.key);
              acc.push(newCol);
            });
          }
        } else {
          acc.push(clone(col));
        }
        return acc;
      }, [])
      .forEach(column => {
        const colProps = getProperties(column, {
          tableKey: table.key,
          props,
          builder,
          entryPath,
          functions
        });

        const colComponents = column.components
          .filter(each => each.ignore !== true)
          .map(each => ({ ...each, bindData: column.bindData }));

        if (colComponents.length === 0) {
          delete colProps.component;
        } else if (colComponents.length === 1) {
          const colComp = colComponents[0];
          colProps.component = ft[colComp.type]({
            ...getProperties(colComp, {
              tableKey: table.key,
              props,
              builder,
              entryPath,
              functions
            })
          });
        } else if (colComponents.length > 1) {
          colProps.component = colComponents.map(colComp => {
            return ft[colComp.type](colComp.key, {
              ...getProperties(colComp, {
                tableKey: table.key,
                props,
                builder,
                entryPath,
                functions
              })
            });
          });
        }

        builder.addColumn(column.key, colProps);
      });

    builder.addItems(getItems(table, { props, entryPath, functions }));

    // ! toolbarButton에서는 component를 적용하지 않음
    table.toolbars
      .filter(each => each.ignore !== true)
      .forEach(toolbar => {
        const { buttons } = toolbar;

        buttons
          .filter(each => each.ignore !== true)
          .forEach(button => {
            if (button.isAction) {
              builder.addToolbarAction(button.key, {
                text: button.text,
                ...getProperties(button, {
                  props,
                  builder,
                  entryPath,
                  functions
                })
              });
            } else {
              builder.addToolbarButton(button.key, {
                text: button.text,
                ...getProperties(button, {
                  props,
                  builder,
                  entryPath,
                  functions
                })
              });
            }
          });
      });
  });

  return builder;
};

const getSingleOrMultiComp = (
  components,
  { formKey, fieldKey, props, functions, builder, entryPath }
) => {
  const results = components.reduce((acc, comp) => {
    const { key: originKey, type, bindData } = comp;
    let key = originKey;
    if (bindData) {
      key = getValueFromBind(bindData, key);
      comp.key = key;
    }

    acc = acc.concat(
      ft[type](key || fieldKey, {
        ...getProperties(comp, {
          formKey,
          fieldKey,
          props,
          functions,
          builder,
          entryPath
        })
      })
    );

    return acc;
  }, []);

  if (results.length === 0) {
    return "";
  } else if (results.length === 1) {
    return results[0];
  } else {
    return results;
  }
};

const getFunction = (uid, { functions }) => {
  if (!uid) {
    throw new Error(`Function ID is not provided`);
  }

  const functionString = functions[uid]?.content || "";
  if (!functionString) {
    if (uid === "global") {
      return new Function("return {};");
    } else {
      throw new Error(`Function Body is empty with ID ${uid}`);
    }
  }

  if (uid === "global") {
    return new Function(functionString);
  }

  return new Function(
    "context",
    [
      // ["return", isAsync ? "async" : "", "() => {"].join(" "),
      "return",
      functionString
      // "}"
    ].join(" ")
  );
};

const getFormsTablesData = ({ props, resultData, targetObject }) => {
  const { _dispatch } = props;

  if (isTruthy(targetObject.forms)) {
    targetObject.forms.forEach(form => {
      const newFormData = resultData[`forms.${form.key}`];

      if (newFormData) {
        form.containers.forEach(container => {
          container.fields.forEach(field => {
            field.components.forEach(comp => {
              if (Object.hasOwn(newFormData, comp.key)) {
                _dispatch(
                  rendererActions._updateFormData({
                    formKey: form.key,
                    componentKey: comp.key,
                    value: newFormData[comp.key]
                  })
                );
              }
            });
          });
        });
      }
    });
  }

  if (isTruthy(targetObject.tables)) {
    targetObject.tables.forEach(table => {
      const newTableData = resultData[`tables.${table.key}`];

      if (newTableData) {
        _dispatch(
          rendererActions._overwriteTableData({
            key: table.key,
            value: newTableData
          })
        );
      }
    });
  }

  if (isTruthy(targetObject.codeeditors)) {
    targetObject.codeeditors.forEach(codeeditor => {
      const newCodeEditorData = resultData[`codeeditors.${codeeditor.key}`];

      if (newCodeEditorData) {
        _dispatch(
          rendererActions._updateComponentData({
            componentCtg: "codeeditors",
            componentKey: codeeditor.key,
            value: newCodeEditorData
          })
        );
      }
    });
  }

  if (isTruthy(targetObject.nodeeditors)) {
    targetObject.nodeeditors.forEach(nodeeditor => {
      const newNodeEditorData = resultData[`nodeeditors.${nodeeditor.key}`];

      if (newNodeEditorData) {
        _dispatch(
          rendererActions._updateComponentData({
            componentCtg: "nodeeditors",
            componentKey: nodeeditor.key,
            value: newNodeEditorData
          })
        );
      }
    });
  }
  // TODO: Object.hasOwn(resultData, dialogDataKey)로 변경하여야 함;
  // 위에도 같은 맥락으로 수정해야 할것들이 있음;
  // 이유: 실제로 falsy 값을 명시적으로 주고 싶어도 줄수 없기때문;
  const dialogDataKey = `dialogs.${targetObject.key}`;
  const dialogResultData = resultData[dialogDataKey];
  if (dialogResultData !== undefined) {
    if (Object.hasOwn(dialogResultData, "isOpen")) {
      _dispatch(
        rendererActions._updateDialogData({
          dialogKey: targetObject.key,
          key: "isOpen",
          value: dialogResultData.isOpen
        })
      );
    }
  }
};

const getParams = (meta, match) => {
  const { entryPath } = match.params;
  const origin = meta.paths[0]?.origin;
  if (entryPath === undefined) {
    return {};
  }
  const arrEntryPath = entryPath.split("/").filter(Boolean);
  const arrPattern = origin.split("/").filter(Boolean);

  const matchParams = arrPattern.reduce((acc, part, idx) => {
    if (part.startsWith(":")) {
      const paramName = part.replace(":", "");
      acc[paramName] = arrEntryPath[idx];
    }
    return acc;
  }, {});

  return matchParams;
};

export const checkPreviewMode = match => {
  return match.path === "/lc-ui5/preview/:entryPath+";
};

function wrapNavFunc(funcName, history, match) {
  return (...args) => {
    const [firstArg, ...restArgs] = args;
    const isPreview = checkPreviewMode(match);

    if (isPreview) {
      const path = isString(firstArg) ? firstArg : firstArg.pathname;

      if (path.startsWith("/")) {
        const newPath = ["/lc-ui5/preview/", path.replace("/", "")].join("");
        const newFirstArg = isString(firstArg)
          ? newPath
          : { ...firstArg, pathname: newPath };

        return history[funcName](newFirstArg, ...restArgs);
      }
    }

    return history[funcName](...args);
  };
}

function wrapHistory(history, location, match) {
  return {
    push: wrapNavFunc("push", history, match),
    replace: wrapNavFunc("replace", history, match),
    state: location.state
  };
}

export const executeFunction = (
  props,
  {
    functions,
    uid,
    moreContext,
    isAsync,
    functionIdentifier,
    returnContextKeys,
    oEvent
  }
) => {
  const { _dispatch, history, _state, currentUser, match, location } = props;
  const { data, meta } = _state;
  const params = getParams(meta, match);

  const mode = meta.paths[0]?.mode;

  const context = {
    state: data,
    history: wrapHistory(history, location, match),
    api: reducedApiHub,
    currentUser,
    mode,
    params,
    s3,
    file,
    fn: {},
    oEvent,
    ...moreContext
  };

  context.require = name => {
    if (lib[name] === undefined) {
      throw new Error(
        [
          name,
          "모듈이 제공되지 않는것 같습니다. 시스템 관리자에게 문의해주세요"
        ].join(" ")
      );
    } else {
      return lib[name];
    }
  };

  const contextKeys = Object.keys(context);

  if (returnContextKeys === true) {
    return contextKeys;
  }

  tryit(() => {
    const getGlobalFunctions = getFunction("global", {
      functions
    });
    context.fn = getGlobalFunctions();
  });

  let newData;

  let customFunc;

  try {
    customFunc = getFunction(uid, {
      functions
    });
  } catch (ex) {
    console.log(ex.message);
    return;
  }

  try {
    // console.log(`running function[${uid}][${functionIdentifier}]`);
    newData = customFunc()(context);
  } catch (err) {
    // console.log(
    //   `Failed - function[${uid}][${functionIdentifier}]`,
    //   err.name,
    //   err.message
    // );
    console.log(err.message);
    return;
  }

  if (!isAsync) {
    return newData;
  }

  return newData.then(resultData => {
    if (resultData) {
      if (isFunction(resultData)) {
        _dispatch(
          rendererActions._overwriteState(draft => {
            resultData(draft);
          })
        );
        return;
      }
      if (resultData.messages) {
        _dispatch(
          rendererActions._overwriteState(draft => {
            draft.messages = resultData.messages;
          })
        );
      }

      Object.keys(meta).forEach(each => {
        if (each === "dialogs") {
          meta.dialogs.forEach(dialog => {
            getFormsTablesData({
              props,
              resultData,
              targetObject: dialog
            });
          });
        }
        if (["forms", "tables", "codeeditors", "nodeeditors"].includes(each)) {
          getFormsTablesData({
            props,
            resultData,
            targetObject: meta
          });
        }
      });

      if (isTruthy(resultData.hints)) {
        const { hints } = resultData;
        Object.entries(hints).forEach(([key, newHintData]) => {
          _dispatch(
            rendererActions._updateDataHints({
              hintKey: key,
              data: newHintData
            })
          );
        });
      }
    }
  });
};
