(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@bsgp/form-and-table"), require("@bsgp/ft-react"), require("@bsgp/lib-api"), require("@bsgp/lib-core"), require("@bsgp/lib-date"), require("@bsgp/lib-date/v2"), require("@bsgp/lib-export"), require("@bsgp/lib-hooks"), require("@bsgp/lib-quantity"), require("@reduxjs/toolkit"), require("actions/ui5"), require("jsbarcode"), require("react"), require("react-redux"), require("react-router-dom"), require("ui5-lib-rc"));
	else if(typeof define === 'function' && define.amd)
		define(["@bsgp/form-and-table", "@bsgp/ft-react", "@bsgp/lib-api", "@bsgp/lib-core", "@bsgp/lib-date", "@bsgp/lib-date/v2", "@bsgp/lib-export", "@bsgp/lib-hooks", "@bsgp/lib-quantity", "@reduxjs/toolkit", "actions/ui5", "jsbarcode", "react", "react-redux", "react-router-dom", "ui5-lib-rc"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@bsgp/form-and-table"), require("@bsgp/ft-react"), require("@bsgp/lib-api"), require("@bsgp/lib-core"), require("@bsgp/lib-date"), require("@bsgp/lib-date/v2"), require("@bsgp/lib-export"), require("@bsgp/lib-hooks"), require("@bsgp/lib-quantity"), require("@reduxjs/toolkit"), require("actions/ui5"), require("jsbarcode"), require("react"), require("react-redux"), require("react-router-dom"), require("ui5-lib-rc")) : factory(root["@bsgp/form-and-table"], root["@bsgp/ft-react"], root["@bsgp/lib-api"], root["@bsgp/lib-core"], root["@bsgp/lib-date"], root["@bsgp/lib-date/v2"], root["@bsgp/lib-export"], root["@bsgp/lib-hooks"], root["@bsgp/lib-quantity"], root["@reduxjs/toolkit"], root["actions/ui5"], root["jsbarcode"], root["react"], root["react-redux"], root["react-router-dom"], root["ui5-lib-rc"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__158__, __WEBPACK_EXTERNAL_MODULE__961__, __WEBPACK_EXTERNAL_MODULE__677__, __WEBPACK_EXTERNAL_MODULE__228__, __WEBPACK_EXTERNAL_MODULE__91__, __WEBPACK_EXTERNAL_MODULE__944__, __WEBPACK_EXTERNAL_MODULE__177__, __WEBPACK_EXTERNAL_MODULE__377__, __WEBPACK_EXTERNAL_MODULE__258__, __WEBPACK_EXTERNAL_MODULE__96__, __WEBPACK_EXTERNAL_MODULE__389__, __WEBPACK_EXTERNAL_MODULE__357__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__148__, __WEBPACK_EXTERNAL_MODULE__442__, __WEBPACK_EXTERNAL_MODULE__877__) => {
return 