import { createSlice } from "@reduxjs/toolkit";
import { isTruthy, isObject, makeid, clone, defined } from "@bsgp/lib-core";
import {
  getTargetArray,
  getTargetObj,
  getDefaultPathValue,
  metaIndexMap,
  metaKeyStucture,
  replaceInvalidChar,
  getFunctionHeader,
  getBindParams,
  getParentObjects,
  getOptionTarget
} from "./modules";
import {
  // form as initialForm,
  // field as initialField,
  dialog as initialDialog
} from "./forms";
import { splitSpecialKeyIfExists } from "../mods";

const initialMeta = {
  id: "",
  description: "",
  wrapForms: true,
  renderAsReact: false,
  paths: [],
  forms: [],
  tables: [],
  dialogs: [],
  codeeditors: [],
  nodeeditors: [],
  headers: [],
  functions: {
    initialization: { type: "js", content: "" },
    global: { type: "js", content: "" }
  }
};

export const metaAttributes = Object.keys(initialMeta);

const metaSlice = createSlice({
  name: "metaSlice",
  initialState: initialMeta,
  reducers: {}
});

export const initialState = {
  ...initialMeta,
  extra: {
    selectedPath: "",
    errorMessage: "",
    metaList: [],
    message: "",
    version: {
      description: ""
    },
    move: {
      dialogKey: ""
    },
    export: {
      globalFunction: false,
      remain: false
    },
    deploy: {
      partner: "",
      system: "",
      partners: [],
      systems: [],
      version: "",
      description: "",
      presys: []
    },
    dialog: {
      isOpen: false,
      isCodeEditorOpen: false,
      isDeployOpen: false,
      isCreateVersionOpen: false,
      isMoveOpen: false,
      invalid: {},
      target: {
        keys: [],
        fIdx: 0,
        cIdx: 0,
        fiIdx: null,
        compIdx: 0,
        hIdx: 0, // header Index
        bIdx: 0, // button(header) Index
        lIdx: 0, // link(header) Index
        tIdx: 0, // table Index
        coIdx: 0, // Column Index
        dIdx: 0, // dialog Index
        codeIdx: 0, // codeEditor Index
        nodeIdx: 0, // nodeEditor Index
        idxF: 0,
        idxC: 0,
        idxFi: null,
        idxT: 0,
        idxCo: 0,
        toIdx: 0, // Toolbar Index
        functionUid: "",
        type: ""
      }
    }
  }
};

function migrateFormsTables(meta) {
  if (Object.hasOwn(meta, "tables")) {
    meta.tables.forEach(table => {
      if (table.tableItems) {
        table.items = table.tableItems;
        delete table.tableItems;
      }
      //       if (Array.isArray(table.properties)) {
      //         table.properties = {};
      //       }
      //       if (table.columns.length > 0) {
      //         table.columns.forEach(col => {
      //           if (col.onPress) {
      //             col.components[0].onPress = col.onPress;
      //             delete col.onPress;
      //           }
      //         });
      //       }
    });
  }

  if (Object.hasOwn(meta, "codeeditors")) {
    meta.codeeditors.forEach(codeeditor => {
      if (codeeditor.buttons) {
        codeeditor.components = codeeditor.buttons;
        delete codeeditor.buttons;
      }
    });
  }
  if (Object.hasOwn(meta, "nodeeditors")) {
    meta.nodeeditors.forEach(nodeeditor => {
      if (nodeeditor.buttons) {
        nodeeditor.components = nodeeditor.buttons;
        delete nodeeditor.buttons;
      }
    });
  }
  if (Object.hasOwn(meta, "forms")) {
    meta.forms.forEach((form, fIdx) => {
      //       const indecies = [fIdx];
      //       if (form.sizeV2 === undefined) {
      //         form.sizeV2 = clone(initialForm.sizeV2);
      //       }
      if (form.containers.length > 0) {
        form.containers.forEach((container, ctIdx) => {
          if (isObject(container.ignore)) {
            container.ignore = false;
          }
          //           indecies.push(ctIdx);
          //           if (container.fields.length > 0) {
          //             container.fields.forEach((field, fldIdx) => {
          //               indecies.push(fldIdx);
          //               field.type = "";
          //               if (field.noWrap === undefined) {
          //                 field.noWrap = clone(initialField.noWrap);
          //               }
          //               if (field.components === undefined) {
          //                 field.components = field.component;
          //                 delete field.component;
          //               }
          //               if (field.components.length > 0) {
          //                 field.components.forEach((comp, cpIdx) => {
          //                   indecies.push(cpIdx);
          //                   if (!!comp.compKey) {
          //                     comp.key = comp.compKey.value;
          //                     delete comp.compKey;
          //                   } else if (!comp.compKey) {
          //                     // pass;
          //                   }
          //                   if (!!comp.compType) {
          //                     comp.type = comp.compType.value;
          //                     delete comp.compType;
          //                   } else if (!comp.compType) {
          //                     // pass
          //                   }
          //                   if (!comp.type) {
          //                     comp.type = "Input";
          //                   }
          //                   if (comp.styleType) {
          //                     comp.properties.type = comp.styleType;
          //                     delete comp.styleType;
          //                   }
          //                   if (comp.properties) {
          //              Object.keys(comp.properties).forEach(propKey => {
          //                       comp.properties[propKey].value = String(
          //                         comp.properties[propKey].value
          //                       );
          //                     });
          //                   } else if (comp.properties === undefined) {
          //                     // todo: undefined여도 빈 객체를 넣을 수 있어야 한다
          //                     comp.properties = {};
          //                     comp.valueHelpV2 = {};
          //                   }
          //                   if (comp.value === undefined) {
          //                     const keys = [
          //                       "forms",
          //                       "containers",
          //                       "fields",
          //                       "components"
          //                     ];
          //                     comp.value = {
          //         value: getDefaultPathValue("value", keys, meta, {
          //                         indecies
          //                       }),
          //                       type: "Path"
          //                     };
          //                   }
          //                 });
          //               }
          //             });
          //           }
        });
      }
    });
  }
}

function convertToNewStructure(meta) {
  const clonedMeta = clone(meta);
  if (Object.hasOwn(clonedMeta, "functions")) {
    if (clonedMeta.functions.global === undefined) {
      clonedMeta.functions.global = {
        type: "js",
        content: builderSlice.getInitialState().functions.global.content
      };
    }
  }
  if (Object.hasOwn(clonedMeta, "headers")) {
    if (
      clonedMeta.headers.length > 0 &&
      clonedMeta.headers[0].links === undefined
    ) {
      clonedMeta.headers[0].links = [];
    }
  }

  // if (Object.hasOwn(clonedMeta, "headers")) {
  //   if (
  //     clonedMeta.headers.length > 0 &&
  //     clonedMeta.headers[0].buttons === undefined
  //   ) {
  //     clonedMeta.headers = [
  //       { key: "headers", title: "headers", buttons: clonedMeta.headers }
  //     ];
  //   }
  // }
  migrateFormsTables(clonedMeta);
  if (Object.hasOwn(clonedMeta, "dialogs")) {
    clonedMeta.dialogs.forEach(dialog => {
      // if (!Object.hasOwn(dialog, "codeeditors")) {
      //   dialog.codeeditors = [];
      // }
      migrateFormsTables(dialog);
    });
  }
  return clonedMeta;
}

function recoverMeta(state) {
  if (state.extra.backup !== undefined) {
    metaAttributes.forEach(attr => {
      state[attr] = state.extra.backup[attr];
    });
    delete state.extra.backup;
  }
}

function moveElementByIndex(items, firstIndex, secondIndex) {
  const results = items.slice();
  const removedElement = results.splice(firstIndex, 1)[0];
  results.splice(secondIndex, 0, removedElement);

  return results;
}

// function immutablySwapItems(items, firstIndex, secondIndex) {
//   // ref: https://stackoverflow.com/a/41127618
//   // Constant reference - we can still modify the array itself
//   const results = items.slice();
//   const firstItem = items[firstIndex];
//   results[firstIndex] = items[secondIndex];
//   results[secondIndex] = firstItem;

//   return results;
// }

function findComponents(metaObj, dialogTarget) {
  const components = [];
  if (metaObj.columns) {
    metaObj.columns.forEach((col, coIdx) => {
      if (col.components) {
        col.components.forEach((comp, compIdx) => {
          const newTarget = clone(dialogTarget);
          newTarget.keys.push("columns");
          newTarget.keys.push("components");
          newTarget[metaIndexMap.columns] = coIdx;
          newTarget[metaIndexMap.components] = compIdx;
          components.push({ comp, target: newTarget });
        });
      }
    });
  } else if (metaObj.containers) {
    metaObj.containers.forEach((container, cIdx) => {
      if (container.fields) {
        container.fields.forEach((field, fiIdx) => {
          if (field.components) {
            field.components.forEach((comp, compIdx) => {
              const newTarget = clone(dialogTarget);
              newTarget.keys.push("containers");
              newTarget.keys.push("fields");
              newTarget.keys.push("components");
              newTarget[metaIndexMap.containers] = cIdx;
              newTarget[metaIndexMap.fields] = fiIdx;
              newTarget[metaIndexMap.components] = compIdx;
              components.push({ comp, target: newTarget });
            });
          }
        });
      }
    });
  }
  return components;
}

const builderSlice = createSlice({
  name: "builderSlice",
  initialState,
  reducers: {
    _updateMetaProperty: (state, action) => {
      const { key, value, extra } = action.payload;
      if (extra === true) {
        state.extra[key] = value;
        if (key === "errorMessage") {
          state.extra.message = "";
        } else if (key === "message") {
          state.extra.errorMessage = "";
        }
      } else {
        state[key] = value;
      }
    },
    _updateRequiredKey: (state, action) => {
      const { requiredKey, value } = action.payload;
      const { metaObj, lastKey } = getTargetObj(state);
      const { dialog } = state.extra;

      metaObj[requiredKey] = value;

      if (requiredKey === "key") {
        if (["tables", "forms"].includes(lastKey)) {
          findComponents(metaObj, dialog.target).forEach(({ comp, target }) => {
            if (comp.value?.type === "Path") {
              comp.value.value = getDefaultPathValue(
                "value",
                target.keys,
                state,
                {
                  target: target
                }
              );
            }
          });
        } else if (metaObj.value?.type === "Path") {
          metaObj.value.value = getDefaultPathValue(
            "value",
            dialog.target.keys,
            state,
            {
              target: dialog.target
            }
          );
        }
      } else if (requiredKey === "metaId") {
        if (dialog.target.type === "dialogs") {
          Object.keys(metaKeyStucture[dialog.target.type]).forEach(key => {
            metaObj[key] = clone(initialDialog[key]);
          });
        }
      }
    },
    _moveToDialog: (state, action) => {
      const { dialogKey } = action.payload;

      const { target } = state.extra.dialog;

      const { rootKey_s, indexValue } = target;

      const targetObj = state[rootKey_s][indexValue];

      const dialog = state.dialogs.find(dialog => dialog.key === dialogKey);
      dialog[rootKey_s].push(targetObj);

      state[rootKey_s].splice(indexValue, 1);
    },
    _moveFromDialog: (state, action) => {
      const { index } = action.payload;
      const { lastKey, lastIndex, metaObj, metaArray } = getTargetObj(state);

      const numIndex = Number(index);

      if (!isNaN(numIndex) && state[lastKey].length > index) {
        state[lastKey].splice(index, 0, metaObj);
      } else {
        state[lastKey].push(metaObj);
      }

      metaArray.splice(lastIndex, 1);
    },
    _moveObject: (state, action) => {
      const { newIndex } = action.payload;
      const {
        parentObj: parentObject,
        lastKey: parentArrayKey,
        lastIndex: currentIndex
      } = getTargetObj(state);

      const newParentArray = moveElementByIndex(
        parentObject[parentArrayKey],
        currentIndex,
        newIndex
      );
      parentObject[parentArrayKey] = newParentArray;
    },
    _moveObjectToAnotherParent: (state, action) => {
      const { newParentKey } = action.payload;
      const { list: parentObjects } = getParentObjects(
        state,
        state.extra.dialog.target.keys
      );
      const targetParentObject = parentObjects.find(
        each => each.key === newParentKey
      );

      const { lastIndex, metaObj, metaArray } = getTargetObj(state);

      targetParentObject[state.extra.dialog.target.type].push(metaObj);
      metaArray.splice(lastIndex, 1);
    },
    _openCodeEditorDialog: (state, action) => {
      const { uid, isAsync, contextKeys } = action.payload;

      state.extra.dialog.target.functionUid = uid;
      state.extra.dialog.target.isAsync = isAsync;
      state.extra.dialog.target.contextKeys = contextKeys;
      state.extra.dialog.isCodeEditorOpen = true;

      state.extra.backup = metaAttributes.reduce((acc, attr) => {
        acc[attr] = clone(state[attr]);
        return acc;
      }, {});

      if (!state.functions[uid].content) {
        if (uid === "global") {
          state.functions[uid].content =
            "return {\n\ttestFunction: async () => {\n\n\t}\n};";
        } else {
          const contents = isAsync ? "async () => {\n\n};" : "() => {\n\n};";
          state.functions[uid].content = getFunctionHeader(state.extra.dialog, {
            addBracket: true
          }).concat(contents);
        }
      }
    },

    _addPath: state => {
      state.paths.push({});
    },

    _updateTableCell: (state, action) => {
      const { index, value, cellName, tabName } = action.payload;
      const row = state[tabName][index];
      if (row) {
        row[cellName] = value;
      }
    },

    _updateTableRow: (state, action) => {
      const { index, row, tabName } = action.payload;

      Object.keys(row).forEach(cellName => {
        state[tabName][index][cellName] = row[cellName];
      });
    },

    _addExtraObject: (state, action) => {
      const { keys, indecies, obj } = action.payload;
      const newObj = { ...obj };

      const { metaArray, firstIsLast, lastKey } = getTargetArray(
        state,
        keys,
        indecies
      );

      if (firstIsLast === true) {
        const suffix = metaArray.length + 1;

        newObj.key = `${lastKey}${suffix}`;
        newObj.title = `${lastKey}(${suffix})`;

        if (lastKey === "codeeditors") {
          newObj.value = {
            value: [
              getDefaultPathValue("codeeditor", keys, state, { indecies }),
              newObj.key
            ].join("."),
            type: "Path"
          };
        }
        if (lastKey === "nodeeditors") {
          newObj.value = {
            value: [
              getDefaultPathValue("nodeeditor", keys, state, { indecies }),
              newObj.key
            ].join("."),
            type: "Path"
          };
        }
      } else {
        if (!newObj.key) {
          newObj.key = makeid(5, "a");
        }
        if (lastKey === "components") {
          if (keys.filter(key => key !== "dialogs")[0] === "tables") {
            newObj.type = "Text";
          }
          newObj.value = {
            value: [
              getDefaultPathValue("value", keys, state, { indecies }),
              newObj.key
            ].join("."),
            type: "Path",
            seq: 0
          };
        }
        if (lastKey === "codeeditors") {
          const suffix = metaArray.length + 1;
          newObj.title = `${lastKey}(${suffix})`;

          newObj.value = {
            value: [
              getDefaultPathValue("codeeditor", keys, state, { indecies }),
              newObj.key
            ].join("."),
            type: "Path"
          };
        }
        if (lastKey === "nodeeditors") {
          const suffix = metaArray.length + 1;
          newObj.title = `${lastKey}(${suffix})`;

          newObj.value = {
            value: [
              getDefaultPathValue("nodeeditor", keys, state, { indecies }),
              newObj.key
            ].join("."),
            type: "Path"
          };
        }
      }

      metaArray.push(newObj);
    },
    _updateInitFunc: (state, action) => {
      const { value } = action.payload;

      state.functions.initialization.content = value;
    },
    _openEditDialog2: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.extra.dialog.target[key] = value;
      });

      state.extra.dialog.isOpen = true;

      state.extra.backup = metaAttributes.reduce((acc, attr) => {
        acc[attr] = clone(state[attr]);
        return acc;
      }, {});
    },
    _addProperties: (state, action) => {
      const { metaObj: currentDialog } = getTargetObj(state);

      let dialogTablePropsPairs = [];
      let dialogTableVHV2Pairs = [];

      const dialogTableKeyPairs = Object.entries(currentDialog).filter(
        ([, value]) => {
          if (isObject(value)) {
            return (
              (Object.hasOwn(value, "type") && Object.hasOwn(value, "value")) ||
              (Object.hasOwn(value, "type") && Object.hasOwn(value, "seq"))
            );
          }
          return false;
        }
      );
      if (isTruthy(currentDialog.properties)) {
        dialogTablePropsPairs = Object.entries(currentDialog.properties);
      }
      if (isTruthy(currentDialog.valueHelpV2)) {
        dialogTableVHV2Pairs = Object.entries(currentDialog.valueHelpV2);
      }

      currentDialog[""] = { seq: 0, value: "", type: "String" };

      if (
        isTruthy(dialogTableKeyPairs) ||
        isTruthy(dialogTablePropsPairs) ||
        isTruthy(dialogTableVHV2Pairs)
      ) {
        //* key, props 둘 중에 하나라도 있을 때: seq = lastSeq + 1
        const dialogOptions = dialogTableKeyPairs
          .concat(dialogTablePropsPairs)
          .concat(dialogTableVHV2Pairs);

        const dialogOptionsSeqs = dialogOptions.map(each => {
          return each[1].seq;
        });

        const maxSeq = dialogOptionsSeqs.reduce((acc, each) => {
          if (acc < each) {
            acc = each;
          }
          return acc;
        });

        currentDialog[""].seq = maxSeq + 1;
      }
    },
    _updateIgnoreOption: (state, action) => {
      const { value, key } = action.payload;
      const { metaObj: currentDialog } = getTargetObj(state);
      getOptionTarget(key, currentDialog).ignore = value;
    },
    _updateKeyColumn: (state, action) => {
      const { oldKey, newKey } = action.payload;
      const { metaObj: currentDialog } = getTargetObj(state);

      getOptionTarget(newKey, currentDialog, { replaceWith: oldKey });
    },

    _updateValueColumn: (state, action) => {
      const { nowKey, newValue } = action.payload;
      const { metaObj: currentDialog } = getTargetObj(state);

      getOptionTarget(nowKey, currentDialog).value = newValue;
    },
    _updateValueType: (state, action) => {
      const { newType, row } = action.payload;

      let newValue = row.value;

      switch (newType) {
        case "Function": {
          newValue = makeid(4);

          while (state.functions[newValue]) {
            newValue = makeid(4);
          }

          state.functions[newValue] = {
            type: "js",
            content: ""
          };

          break;
        }
        case "Path": {
          const { dialog } = state.extra;
          newValue = getDefaultPathValue(row.key, dialog.target.keys, state, {
            target: dialog.target
          });
          break;
        }
        default: {
          break;
        }
      }

      const { metaObj: currentDialog } = getTargetObj(state);

      if (splitSpecialKeyIfExists(row.key)) {
        const [propsKey, propsName] = splitSpecialKeyIfExists(row.key);
        currentDialog[propsKey][propsName].value = newValue;
        currentDialog[propsKey][propsName].type = newType;
      } else {
        currentDialog[row.key].value = newValue;
        currentDialog[row.key].type = newType;
      }
    },
    // _deleteFromTableRow: (state, action) => {
    //   const { type, target } = action.payload;
    //   const { metaObj: currentDialog } = getTargetObj(state);

    // },
    _updateOptionsFunc: (state, action) => {
      const { newValue, resultText } = action.payload;
      const { functions, extra } = state;

      functions[extra.dialog.target.functionUid].content = newValue;
      extra.dialog.target.resultText = resultText;
    },
    _undoDialogUpdate: (state, action) => {
      recoverMeta(state);
    },
    _closeDialog: (state, action) => {
      state.extra.dialog.isOpen = false;
    },
    _toggleDeployDialog: (state, action) => {
      const { isOpen } = action.payload;
      state.extra.dialog.isDeployOpen = isOpen;
    },
    _updateDeployValue: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state.extra.deploy[key] = action.payload[key];
      });
    },
    _toggleCreateVersionDialog: (state, action) => {
      const { isOpen } = action.payload;
      state.extra.dialog.isCreateVersionOpen = isOpen;
    },
    _updateVersionValue: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state.extra.version[key] = action.payload[key];
      });
    },
    _toggleMoveDialog: (state, action) => {
      const { isOpen } = action.payload;
      state.extra.dialog.isMoveOpen = isOpen;

      if (isOpen && action.payload.options) {
        state.extra.dialog.target.keys = [];
        Object.entries(action.payload.options).forEach(([key, value]) => {
          state.extra.dialog.target[key] = value;
        });
      }
    },
    _toggleExportDialog: (state, action) => {
      const { isOpen } = action.payload;
      state.extra.dialog.isExportOpen = isOpen;

      if (isOpen && action.payload.options) {
        state.extra.export = { globalFunction: false, remain: false };
        Object.entries(action.payload.options).forEach(([key, value]) => {
          state.extra.dialog.target[key] = value;
        });
      }
    },
    _exportMeta: (state, action) => {
      const { funcValues } = action.payload;
      const { remain } = state.extra.export;
      const { rootKey_s, indexValue } = state.extra.dialog.target;

      if (!remain) {
        funcValues.forEach(value => {
          delete state.functions[value];
        });
        state[rootKey_s].splice(indexValue, 1);
      }
    },
    _updateMoveValue: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state.extra.move[key] = action.payload[key];
      });
    },
    _updateExportValue: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state.extra.export[key] = action.payload[key];
      });
    },
    _confirmCodeorUpdate: (state, action) => {
      state.extra.dialog.isCodeEditorOpen = false;
    },
    _cancelCodeEditorUpdate: (state, action) => {
      const { dialog } = state.extra;

      dialog.isCodeEditorOpen = false;

      recoverMeta(state);
    },
    _getMetaData: (state, action) => {
      const initialState = metaSlice.getInitialState();
      const convertedMeta = convertToNewStructure(action.payload);

      Object.keys(convertedMeta).forEach(key => {
        if (convertedMeta[key] !== undefined) {
          state[key] = convertedMeta[key];
        } else {
          state[key] = initialState[key];
        }

        if (state.paths.length > 0) {
          // 호환성때문에 임시로 추가한 로직;
          state.paths = state.paths.map(each => ({
            ...each,
            origin: defined(each.origin, each.value)
          }));
        }

        if (state.paths.length > 0) {
          state.extra.selectedPath = state.paths[0].origin;
        } else {
          const { selectedPath } = builderSlice.getInitialState().extra;
          state.extra.selectedPath = selectedPath;
        }
        state.title = state.paths.find(
          each => each.origin === state.extra.selectedPath
        )?.title;
      });
    },
    _initAll: () => {
      return builderSlice.getInitialState();
    },
    _updateDialogInvalid: (state, action) => {
      const { requiredKey, value } = action.payload;
      const { dialog } = state.extra;

      if (requiredKey === "key") {
        const params = getBindParams(value);
        const valueWithoutParams = params.reduce((acc, each) => {
          return acc.replace(each, "");
        }, value);
        const newValue = replaceInvalidChar(valueWithoutParams);
        if (newValue !== valueWithoutParams) {
          dialog.invalid = {
            ...dialog?.invalid,
            key: true
          };
        } else {
          dialog.invalid = {
            ...dialog?.invalid,
            key: false
          };
        }
      }
    }
  }
});

export const builderActions = builderSlice.actions;
export default builderSlice;
